<template>
  <div>
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-form
        @submit.stop.prevent="updateGameLimit"
      >
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="12"
            class="card-filter-item mt-1"
          >
            <!-- Table Container Card -->
            <b-overlay
              :show="loading"
              opacity="0.80"
              rounded="sm"
            >
              <!-- table -->
              <vue-good-table
                :columns="columns"
                :rows="gameLimits"
                :sort-options="{
                  enabled: false,
                }"
                :pagination-options="{
                  enabled: false,
                  perPage:pageLength
                }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <!-- Column: Common -->
                  <span>
                    <b-form-input
                      v-if="props.column.field === 'banker_and_player_min'"
                      v-model="gameLimits[props.row.originalIndex].banker_and_player_min"
                      :disabled="!userData.can_edit"
                      autocomplete="off"
                    />
                    <b-form-input
                      v-if="props.column.field === 'banker_and_player_max'"
                      v-model="gameLimits[props.row.originalIndex].banker_and_player_max"
                      :disabled="!userData.can_edit"
                      autocomplete="off"
                    />
                    <b-form-input
                      v-if="props.column.field === 'tie_min'"
                      v-model="gameLimits[props.row.originalIndex].tie_min"
                      :disabled="!userData.can_edit"
                      autocomplete="off"
                    />
                    <b-form-input
                      v-if="props.column.field === 'tie_max'"
                      v-model="gameLimits[props.row.originalIndex].tie_max"
                      :disabled="!userData.can_edit"
                      autocomplete="off"
                    />
                    <b-form-input
                      v-if="props.column.field === 'pair_min'"
                      v-model="gameLimits[props.row.originalIndex].pair_min"
                      :disabled="!userData.can_edit"
                      autocomplete="off"
                    />
                    <b-form-input
                      v-if="props.column.field === 'pair_max'"
                      v-model="gameLimits[props.row.originalIndex].pair_max"
                      :disabled="!userData.can_edit"
                      autocomplete="off"
                    />
                  </span>
                </template>

                <!-- Columns -->
                <template
                  slot="table-column"
                  slot-scope="props"
                >
                  <span v-text="$t(props.column.label)" />
                </template>
              </vue-good-table>
            </b-overlay>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="12"
            class="card-filter-item mt-1"
          >
            <b-button
              v-if="userData.can_edit"
              variant="primary float-right"
              @click="updateGameLimit"
              v-text="$t('update')"
            />
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import {
  BFormInput,
  BOverlay,
  BForm,
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Account from '@/models/Account'

import { EventBus } from '../../../eventbus'

export default {
  name: 'SettingSetLimit',
  components: {
    BFormInput,
    BOverlay,
    BForm,
    BCard,
    BRow,
    BCol,
    BButton,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      pageLength: 50,
      dir: false,
      columns: [
        {
          label: 'banker_and_player_min',
          field: 'banker_and_player_min',
          type: 'text',
        },
        {
          label: 'banker_and_player_max',
          field: 'banker_and_player_max',
          type: 'text',
        },
        {
          label: 'tie_min',
          field: 'tie_min',
          type: 'text',
        },
        {
          label: 'tie_max',
          field: 'tie_max',
          type: 'text',
        },
        {
          label: 'pair_min',
          field: 'pair_min',
          type: 'text',
        },
        {
          label: 'pair_max',
          field: 'pair_max',
          type: 'text',
        },
      ],
      loading: true,
      gameLimits: [],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      const response = await Account.getGameLimits()
      this.gameLimits = response.data.data
      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
      this.loading = false
    },
    async updateGameLimit() {
      try {
        const response = await Account.updateGameLimit({ gameLimits: this.gameLimits })
        if (response.data.status) {
          this.$swal({
            title: 'Success!',
            text: response.data.results.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.results.message,
            },
          }, {
            position: 'top-center',
          })
          if (typeof response.data.results.field_id !== 'undefined') {
            document.getElementById(response.data.results.field_id).focus()
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
  },
}
</script>

<style>
</style>
